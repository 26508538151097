import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { NavItem, Nav } from 'reactstrap'
import _ from 'lodash'

import ChartPill from './chartPill.js'
import CMSInput from './input.js'
import CMSDate from './date.js'
import CMSTimer from './timepicker.js'
import CMSRichText from './richText.js'
import CMSSelect from './select.js'
import SubmitButton from './submitButton.js'
import switchButton from './switchButton.js'
import CMSSwitch from './toggle.js'
import CMSMultiCheckbox from './multiCheckbox.js'
import CMSMultipleInput from './multipleInput'
import CMSMultipleMobileInput from './multipleMobileNumber'

import CMSRadio from './multiRadio'
import ColorPicker from './colorPicker'
import { cms, dataConnect } from '../../../helper.js'
import DateTimePicker from './dateTimePicker'
import DateTimeRangePicker from './dateTimeRangePicker'
import FilerUpload from './fileUpload.js'
import SectionHeading from './sectionHeading.js'
import Spacer from './spacer.js'
import PreTime from './preTime.js'
import PostTime from './postTime.js'
import AdvancedMultiSelect from './advancedMultiSelect.js'
import EmailCheckBox from './emailCheckbox.js'
import SendReportRadio from './sendReportRadio.js'
import InputReadOnly from '../../Grid/Component/inputReadOnly.js'
import RepeateGap from './repeateGap.js'
import SelectMultipleTemplates from './selectMultipleTemplates.js'
import MinuteGap from './minGap.js'

const select = (state) => ({
  coreData: state.CMS.coreData,
  currentRow: state.CMS.currentRow,
  tabFormData: state.Page.tabFormData,
})

const CMSField = ({
  id,
  data,
  dataConnectionValue,
  coreData,
  onSubmit,
  fieldAction = () => {},
  updateValues,
  updateKeysForReduxData,
  fieldKey,
  toggleActions,
  onChange = () => {},
  formData,
  currentRow,
  tabFormData,
  isModalAction,
  isModel,
  defaultFormValue,
  onValueChange,
  allFieldsInForm,
  submittedFormData,
  parentPageName,
  pageName,
  source_table,
  noDefaultValue,
  formFieldValue = {},
  setFormFieldValue = () => {},
  validationErrorOnFieldValueChange,
  setValidationErrorOnFieldValueChange,
  importedTemplates,
  siblingForms,
  parentForm,
}) => {
  let isCurrentRow,
    isCurrentRowValue,
    isCurrentRowLabel,
    extraDefaultFormValue = {}

  if (tabFormData.data?.hasOwnProperty(data.field_id) && !isModel && !defaultFormValue) {
    isCurrentRow = true
    isCurrentRowValue = tabFormData?.data[data.field_id] || _.get(tabFormData?.data, data?.field_id)
    isCurrentRowLabel = tabFormData?.data[`${data.field_id}_label`]
    const splitted_id = data?.field_id?.split('.')
    if (splitted_id?.length === 2) {
      if (
        tabFormData?.data[splitted_id[0]] &&
        tabFormData?.data[splitted_id[0]]?.[splitted_id[1]] &&
        tabFormData?.data[splitted_id[0]][`${[splitted_id[1]]}_label`]
      ) {
        isCurrentRowValue = tabFormData?.data[splitted_id[0]][splitted_id[1]]
        isCurrentRowLabel = tabFormData?.data[splitted_id[0]][`${[splitted_id[1]]}_label`]
      }
    }
  } else if (defaultFormValue) {
    isCurrentRow = true
    isCurrentRowValue = defaultFormValue[data?.field_id] || _.get(defaultFormValue, data?.field_id)
    isCurrentRowLabel = defaultFormValue[`${data?.field_id}_label` || data?.field_id]
    extraDefaultFormValue['has_expiry'] = defaultFormValue['upload_document']?.['has_expiry']

    if (data?.field_id === 'template_group_location_id') {
      if (!defaultFormValue?.template_group_location_id_label) {
        isCurrentRowLabel = defaultFormValue?.template_group_location?.location?.name
      }
    }
  } else if (typeof tabFormData?.data?.[source_table] === 'object') {
    isCurrentRow = true
    isCurrentRowValue = tabFormData?.data?.[source_table]?.[data?.field_id] || _.get(tabFormData?.data?.[source_table], data?.field_id)
    isCurrentRowLabel = tabFormData?.data?.[source_table]?.[`${data?.field_id}_label` || data?.field_id]
  } else {
    isCurrentRow = false
    isCurrentRowValue = null
  }

  if (noDefaultValue) {
    isCurrentRowValue = null
    isCurrentRow = null
  }

  let fieldType
  if (data?.field_setting?.type === 'pre_time') {
    fieldType = 'pre_time'
  } else if (data?.field_setting?.type === 'post_time') {
    fieldType = 'post_time'
  } else {
    fieldType = data?.field_setting?.field || data?.field || data?.component_name
  }

  // const fieldType = data.field ? data.field : data.component_name

  switch (fieldType) {
    case 'chart_pill':
      return <ChartPill data={data} id={id} value={dataConnectionValue} parentPageName={parentPageName} pageName={pageName} />
    case 'break':
      return (
        <Nav vertical>
          <NavItem className='nav-item-divider' />
        </Nav>
      )
    case 'input':
      if (data?.field_id === 'repeats_gap') {
        return (
          <RepeateGap
            data={data}
            toggleActions={toggleActions}
            id={id}
            value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue}
            onValueChange={onValueChange}
            parentPageName={parentPageName}
            pageName={pageName}
            formName={formData?.name}
            formFieldValue={formFieldValue}
            setFormFieldValue={setFormFieldValue}
          />
        )
      }
      if (data?.field_id === 'min_gap') {
        return (
          <MinuteGap
            data={data}
            toggleActions={toggleActions}
            id={id}
            value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue}
            onValueChange={onValueChange}
            parentPageName={parentPageName}
            pageName={pageName}
            formName={formData?.name}
            formFieldValue={formFieldValue}
            setFormFieldValue={setFormFieldValue}
            siblingForms={siblingForms}
            parentForm={parentForm}
          />
        )
      }
      return (
        <CMSInput
          data={data}
          toggleActions={toggleActions}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue}
          onValueChange={onValueChange}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          siblingForms={siblingForms}
          parentForm={parentForm}
        />
      )
    case 'input_read_only':
      return (
        <InputReadOnly data={data} value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue} inputLabel={isCurrentRowLabel} />
      )
    case 'multiple_input':
      return (
        <CMSMultipleInput
          data={data}
          toggleActions={toggleActions}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue}
          onValueChange={onValueChange}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
        />
      )
    case 'multiple_mobile_number':
      return (
        <CMSMultipleMobileInput
          data={data}
          toggleActions={toggleActions}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue}
          onValueChange={onValueChange}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
        />
      )
    case 'pre_time':
      return (
        <PreTime
          data={data}
          time_field_id={data?.field_setting?.time_field_id}
          onChange={(value) => {}}
          defaultValue={defaultFormValue ? defaultFormValue[data?.field_id] : ''}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
        />
      )
      if (data?.field_setting?.type || data?.field_setting?.time_field_id) {
        return (
          <PreTime
            data={data}
            time_field_id={data?.field_setting?.time_field_id}
            onChange={(value) => {}}
            defaultValue={defaultFormValue ? defaultFormValue[data?.field_id] : ''}
            parentPageName={parentPageName}
            pageName={pageName}
            formName={formData?.name}
          />
        )
      }
    case 'post_time':
      return (
        <PreTime
          data={data}
          time_field_id={data?.field_setting?.time_field_id}
          onChange={(value) => {}}
          defaultValue={defaultFormValue ? defaultFormValue[data?.field_id] : ''}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
        />
      )
      if (data?.field_setting?.type || data?.field_setting?.time_field_id) {
        return (
          <PostTime
            data={data}
            time_field_id={data?.field_setting?.time_field_id}
            onChange={(value) => {}}
            defaultValue={defaultFormValue ? defaultFormValue[data?.field_id] : ''}
            parentPageName={parentPageName}
            pageName={pageName}
            formName={formData?.name}
          />
        )
      } else {
        return <div>Pre Time field Empty</div>
      }
    case 'advanced_multi_select':
      return <AdvancedMultiSelect data={data} parentPageName={parentPageName} pageName={pageName} />

    case 'rich_text':
      return (
        <CMSRichText
          data={data}
          toggleActions={toggleActions}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
        />
      )
    case 'date':
      return (
        <CMSDate
          data={data}
          toggleActions={toggleActions}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          source_table={source_table}
          formData={formData}
          validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
          setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
        />
      )
    case 'time':
      return (
        <CMSTimer
          data={data}
          id={id}
          defaultValue={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          source_table={source_table}
          formData={formData}
          validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
          setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
        />
      )
    case 'switch':
      return (
        <CMSSwitch
          data={data}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
          formName={formData?.name}
          form={formData}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          submittedFormData={submittedFormData}
        />
      )
    case 'toggle_switch':
      return (
        <CMSSwitch
          data={data}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
          formName={formData?.name}
          form={formData}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          submittedFormData={submittedFormData}
        />
      )
    case 'multi_checkbox':
      if (data?.field_id === 'emails' && parentPageName === 'send_reports') return <EmailCheckBox parentPageName={parentPageName} />
      return (
        <CMSMultiCheckbox
          data={data}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          onChange={onChange}
          fieldActions={fieldAction}
        />
      )
    case 'radio':
    case 'multi_radio':
   
      if (data?.field_id === 'send_type')
        return <SendReportRadio data={data} id={id} formFieldValue={formFieldValue} setFormFieldValue={setFormFieldValue} />
      return (
        <CMSRadio
          data={data}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          onChange={onChange}
          pageName={pageName}
          parentPageName={parentPageName}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
        />
      )
    case 'multi_select':
      return <CMSSwitch data={data} id={id} value={isCurrentRow ? isCurrentRowValue : dataConnectionValue} formName={formData?.name} />
    // return <CMSMultiCheckbox data={data} id={id} value={dataConnectionValue} />
    case 'static_text':
      return <p id={id}>{cms(data.cms_key)}</p>
    case 'select':
    case 'dropdown':
      if (data?.field_id === 'templates' && !parentPageName?.includes('user_security'))
        return (
          <SelectMultipleTemplates data={data} submittedFormData={submittedFormData} value={isCurrentRow ? isCurrentRowValue : dataConnectionValue} />
        )

      return (
        <CMSSelect
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          id={id}
          data={data}
          toggleActions={toggleActions}
          fieldKey={fieldKey}
          updateValues={() => updateValues()}
          updateKeysForReduxData={(keys, key) => updateKeysForReduxData(keys, key)}
          onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
          allFieldsInForm={allFieldsInForm}
          repeatingFormItem={submittedFormData}
          inputLabel={isCurrentRowLabel}
          extraDefaultFormValue={extraDefaultFormValue}
          parentPageName={parentPageName}
          pageName={pageName}
          formName={formData?.name}
          formData={formData}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
          setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
          source_table={source_table}
          importedTemplates={importedTemplates}
          parentForm={parentForm}
        />
      )
    case 'submit_api_button':
      return <SubmitButton data={data} onSubmit={onSubmit} id={id} />
    case 'color_picker':
      return <ColorPicker data={data} onChange={onChange} defaultValue={isCurrentRow ? isCurrentRowValue : formData.cms_value} />
    case 'date_time_picker':
      return (
        <DateTimePicker
          data={data}
          onChange={onChange}
          defaultValue={isCurrentRow ? isCurrentRowValue : formData.cms_value}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
        />
      )
    case 'datetime':
      return (
        <DateTimePicker
          data={data}
          onChange={onChange}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          defaultValue={
            isCurrentRow ? (isCurrentRowValue ? new Date(isCurrentRowValue) : '') : formData?.cms_value ? new Date(formData?.cms_value) : ''
          }
          id={id}
          value={isCurrentRow ? isCurrentRowValue : dataConnectionValue}
          onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
        />
      )
    case 'date_time_range_picker':
      return <DateTimeRangePicker data={data} onChange={onChange} />
    case 'file_upload':
      return <FilerUpload data={data} form={formData} onChange={onChange} defaultValue={isCurrentRow ? isCurrentRowValue : formData?.cms_value} />
    case 'section_heading':
      return <SectionHeading data={data} formFieldValue={formFieldValue} submittedFormData={submittedFormData} />
    case 'spacer':
      return <Spacer data={data} />

    default:
      return (
        <CMSInput
          data={data}
          toggleActions={toggleActions}
          id={id}
          value={isCurrentRow ? isCurrentRowValue : data.cms_value || dataConnectionValue}
          onValueChange={onValueChange}
          formName={formData?.name}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          siblingForms={siblingForms}
          parentForm={parentForm}
        />
      )
  }
}
export default connect(select)(CMSField)
