import React, { Fragment, Component, useState } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import {
  Col,
  Row,
  Button,
  Card,
  Nav,
  NavLink,
  NavItem,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  Input,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'

import { Elastic } from 'react-burgers'

import {
  faStar,
  faCalendarAlt,
  faAngleLeft,
  faAngleDown,
  faSearch,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTags,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import IconSet from '../../../../CMSComponent/icon'
import {
  cms,
  getConnectTemplateReduxDataAfterDelete,
  renderBlockColorClass,
  renderBlockColorStyle,
  renderTextColorClass,
  tidyName,
  renderBlockStyle,
  getConnectTemplateReduxDataAfterDeleteClientRow,
  removeInsertDataQueueByReactId,
} from '../../../../helper'
import { clearCurrentRowData, fetchUpdateApiData, setConnectTemplateDataRedux, setCurrentRow, setParentTableData } from '../../../../Redux/Reducers/CMS'
import { setQueue } from '../../../../Redux/Reducers/queue'
import Loader from 'react-loaders'
import moment from 'moment'
import { request } from '../../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { fetchUpdateApi } from '../../../../Redux/Sagas/rootSaga'

const getClassnameForStatus = (status) => {
  switch (status) {
    case 'sent':
      return 'badge badge-pill badge-success ml-1'
    case 'pause':
      return 'badge badge-pill badge-warning ml-1'
    case 'schedule':
      return 'badge badge-pill badge-info ml-1'
  }
}

const MailContent = ({
  isElasticActive,
  setIsElasticActive,
  fetchMoreData,
  content,
  currentPageData,
  meta,
  fetchData,
  setAddDataDetailToEdit,
  selectedGridData,
  selectedRow,
  setSelectedRow,
  setIsChildTabSelected,
  isDataLoading,
  isChildTabSelected,
  setSearchData,
  searchData,
  hidden_tab,
}) => {
  const primaryColor = useSelector((theme) => theme.ThemeOptions.primaryColor)
  const linkClassName = renderTextColorClass(primaryColor)?.split(' ')[0] + '-text'
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)

  const [hoveredRow, setHoveredRow] = useState()

  const [isRowExpanded, setIsRowExpanded] = useState(false)

  const parentTable = parentTableDataInRedux?.[0]?.source_table
  const dispatch = useDispatch()

  const onHandleCheckBox = (checked, id) => {
    if (checked) {
      const data = [...selectedRow]
      data?.push(id)
      setSelectedRow(data)
    } else {
      const data = [...selectedRow]
      const index = selectedRow?.indexOf(id)
      data.splice(index, 1)
      setSelectedRow(data)
    }
  }

  const onDeleteRow = () => {
    const ids_array = selectedRow?.map((item) => parseInt(item))
    const dataToUpdate = getConnectTemplateReduxDataAfterDelete(
      connectTemplateDataRedux,
      selectedGridData?.parentPageName,
      selectedGridData?.parentPageName,
      selectedGridData?.tableGrid?.source_table,
      [],
      false,
      ids_array
    )
    dispatch(setConnectTemplateDataRedux(dataToUpdate))

    const payload = {
      ids: ids_array,
      source_table: selectedGridData?.tableGrid?.source_table,
      domain_id: domain_id,
    }
    request('connect-template-delete', 'POST', payload).then((res) => {
      if (res.status == 200 || res.status == 202) {
        dispatch(setNotification({ message: 'Data deleted successfully!', type: 'success' }))
      } else {
        dispatch(setNotification({ message: 'something went wrong!', type: 'error' }))
      }
      fetchData()
      // dispatch(fetchUpdateApiData())

    })
    //need to implement sync logic
    // dispatch(setQueue({ url: 'connect-template-delete', payload: { data: { ...payload } } }))
  }

  const onDeleteRowById = (id, temporary_id) => {
    const ids_array = [id]
    //sync logic need to implement
    if (selectedGridData?.selectedGrid === 'restore_grid' || true) {
      const payload = {
        ids: ids_array,
        source_table: selectedGridData?.tableGrid?.source_table,
        domain_id: domain_id,
        is_trash: true,
      }
      request('connect-template-delete', 'POST', payload).then((res) => {
        fetchData()
      })
    } else {
      if (!id && temporary_id) {
        const dataToUpdate = getConnectTemplateReduxDataAfterDeleteClientRow(
          connectTemplateDataRedux,
          selectedGridData?.parentPageName,
          selectedGridData?.parentPageName,
          selectedGridData?.tableGrid?.source_table,
          [],
          false,
          [temporary_id]
        )
        removeInsertDataQueueByReactId(temporary_id, selectedGridData?.tableGrid?.source_table, parentTable, currentRow)
        dispatch(setConnectTemplateDataRedux(dataToUpdate))
      } else {
        const dataToUpdate = getConnectTemplateReduxDataAfterDelete(
          connectTemplateDataRedux,
          selectedGridData?.parentPageName,
          selectedGridData?.parentPageName,
          selectedGridData?.tableGrid?.source_table,
          [],
          false,
          ids_array
        )
        dispatch(setConnectTemplateDataRedux(dataToUpdate))

        const payload = {
          ids: ids_array,
          source_table: selectedGridData?.tableGrid?.source_table,
          domain_id: domain_id,
        }
        dispatch(setQueue({ url: 'connect-template-delete', payload: { data: { ...payload } } }))
      }
    }
  }

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      fetchData()
    }
  }

  return (
    <Card className='app-inner-layout__content'>
      <div style={{ width: 'calc(100vw - 37rem)' }}>
        <div className='app-inner-layout__top-pane'>
          <div className='pane-left'>
            <div className='mobile-app-menu-btn'>
              <Elastic
                width={26}
                lineHeight={2}
                lineSpacing={5}
                color='#6c757d'
                active={isElasticActive}
                onClick={() => setIsElasticActive(!isElasticActive)}
              />
            </div>

            {currentRow?.length > 1 && (
              <span>
                <i
                  className='fa-solid fa-arrow-left bg-light'
                  style={{ padding: '0.5rem', cursor: 'pointer', borderRadius: '20px', fontSize: '10px', marginRight: '10px', border: '1px solid' }}
                  onClick={() => {
                    setIsChildTabSelected(false)
                  }}></i>
              </span>
            )}

            <h4 className='mb-0'>
              {selectedGridData?.selectedGrid === 'restore_grid' ? (
                'Trash'
              ) : (
                <>
                  {cms(selectedGridData?.tableGrid?.cms_key) ||
                    cms(selectedGridData?.tableGrid?.cms_tab_key) ||
                    tidyName(selectedGridData?.tableGrid?.name)}
                </>
              )}
            </h4>
          </div>

          <div className='pane-right'>
            {selectedRow?.length > 0 && (
              <Button
                className={`border-0 btn-icon btn-icon-only fsize-1 m-2 btn btn-danger `}
                onClick={() => {
                  onDeleteRow()
                }}>
                <IconSet icon='pe-7s-trash' color='light' />
              </Button>
            )}

            <InputGroup>
              <InputGroupAddon addonType='prepend'>
                <div className='input-group-text'>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              </InputGroupAddon>
              <Input placeholder='Search...' onChange={(e) => setSearchData(e.target.value)} value={searchData} onKeyPress={handleKeyPress} />
              <InputGroupAddon addonType='prepend'>
                <div className='input-group-text' onClick={() => fetchData()}>
                  <IconSet icon='fa-solid fa-rotate' color='dark' style={{ cursor: 'pointer' }} />
                </div>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
        {isDataLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader type='ball-grid-pulse' color='blue' />
          </div>
        ) : (
          <>
            <div className='bg-white p-2' style={{ maxHeight: 'calc(100vh - 18rem)', overflow: 'scroll' }} id='scrollableDiv'>
              {content?.length === 0 ? (
                <div style={{ textAlign: 'center', fontSize: '1.3rem' }} className='mt-4'>
                  Data not added yet!
                </div>
              ) : (
                <Table responsive className='text-nowrap table-lg mb-0' hover>
                  <tbody>
                    {content?.map((item) => {
                      return (
                        <>
                          <tr
                            key={item?.id}
                            onMouseOver={(e) => {
                              setHoveredRow(item?.id)
                            }}
                            onMouseOut={() => {
                              if (hoveredRow === item?.id) {
                                setHoveredRow()
                              }
                            }}
                            className={hoveredRow === item?.id && linkClassName}
                            // style={hoveredRow === item?.id && { ...renderBlockColorStyle(primaryColor) }}
                          >
                            <td className='text-center' style={{ width: '78px' }}>
                              <CustomInput
                                type='checkbox'
                                id={item?.id}
                                value={item?.id}
                                label='&nbsp;'
                                checked={selectedRow?.includes(item?.id?.toString()) ? true : false}
                                onChange={(e) => onHandleCheckBox(e.target.checked, e.target.value)}
                              />
                            </td>
                            {/* <td className='text-center'>
                            <Button
                              className={`btn-icon btn-icon-only btn btn-primary btn-sm`}
                             >
                              <IconSet icon='far fa-edit' color='light'  />
                            </Button>
                          </td> */}
                            <td className='text-center'>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className={`mr-1 `}
                                style={{ color: '#ed5a74', marginBottom: '0.5rem', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  onDeleteRowById(item?.id, item?.temporary_id)
                                }}
                              />
                              {/* <Button
                              className={`border-0 btn-icon btn-icon-only fsize-1 m-2 btn btn-white `}
                              style={{background:'white'}}
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                onDeleteRowById(item?.id)
                              }}>
                              <IconSet icon='pe-7s-trash' color='danger' />
                            </Button> */}
                            </td>

                            <td
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                if (isChildTabSelected) {
                                  if (isRowExpanded === item?.id) {
                                    setIsRowExpanded()
                                  } else {
                                    setIsRowExpanded(item?.id)
                                  }
                                } else {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  if (selectedGridData?.selectedGrid === 'restore_grid') return
                                  dispatch(setCurrentRow(item))
                                  dispatch(
                                    setParentTableData({
                                      source_table: hidden_tab?.source_table,
                                      parent_page_name: hidden_tab?.name,
                                      grid_id: hidden_tab?.main_grid?.id,
                                      child_table: hidden_tab?.child_tabs?.component_call_buttons[0]?.source_table,
                                    })
                                  )
                                  let addDataDetail = {
                                    open: true,
                                    mainGrid: selectedGridData?.tableGrid?.main_grid,
                                    tableGrid: selectedGridData?.tableGrid,
                                    pageName: selectedGridData?.pageName,
                                    parentPageName: selectedGridData?.parentPageName,
                                    isTab: false,
                                    defaultDataFromCurrentRow: true,
                                  }
                                  setAddDataDetailToEdit(addDataDetail)
                                }
                              }}>
                              <div className='widget-content p-0'>
                                <div className='widget-content-wrapper'>
                                  <div className='widget-content-left mr-3'>
                                    <IconSet
                                      icon={'fa-regular fa-users'}
                                      className={hoveredRow === item?.id && linkClassName}
                                      style={{
                                        fontSize: '1.3rem',
                                        fontWeight: '300',
                                        border: '2px solid',
                                        padding: '7px 5px',
                                        borderRadius: '50%',
                                        ...(hoveredRow === item?.id && { ...renderBlockColorStyle(primaryColor) }),
                                      }}
                                      color={'info'}
                                    />
                                  </div>
                                  <div className='widget-content-left'>
                                    <div className='widget-heading'>
                                      {item?.subject}{' '}
                                      {item?.send_schedule_pause && (
                                        <span className={getClassnameForStatus(item.send_schedule_pause)}>{tidyName(item.send_schedule_pause)}</span>
                                      )}
                                    </div>
                                    <div className='widget-subheading'>
                                      {isChildTabSelected && `Sent to ${item?.email}`}
                                    </div>
                                    <div className='widget-subheading'>
                                      {isChildTabSelected
                                        ? `Sent on ${moment(item?.created_at).format('Do MMM, YYYY')}`
                                        : item?.last_sent
                                        ? `Last seen online ${item?.last_sent}`
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              className='text-left'
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                if (isChildTabSelected) {
                                  if (isRowExpanded === item?.id) {
                                    setIsRowExpanded()
                                  } else {
                                    setIsRowExpanded(item?.id)
                                  }
                                } else {
                                  e.stopPropagation()
                                  e.preventDefault()
                                  if (selectedGridData?.selectedGrid === 'restore_grid') return
                                  dispatch(setCurrentRow(item))
                                  let addDataDetail = {
                                    open: true,
                                    mainGrid: selectedGridData?.tableGrid?.main_grid,
                                    tableGrid: selectedGridData?.tableGrid,
                                    pageName: selectedGridData?.pageName,
                                    parentPageName: selectedGridData?.parentPageName,
                                    isTab: false,
                                    defaultDataFromCurrentRow: true,
                                  }
                                  setAddDataDetailToEdit(addDataDetail)
                                }
                              }}>
                              <div
                                className='line-clamp-2'
                                dangerouslySetInnerHTML={{ __html: item?.body }}
                                style={{ width: '30rem', overflow: 'hidden' }}></div>
                            </td>

                            <td className='text-right d-flex align-items-center'>
                              <FontAwesomeIcon className='opacity-4 mr-2' icon={faCalendarAlt} />
                              {moment(item?.created_at).format('Do MMM, YYYY')}
                            </td>
                          </tr>
                          {item?.id === isRowExpanded && (
                            <tr>
                              <td colSpan={5}>
                                <div className='font-weight-bold mb-2' style={{ fontSize: '1.1rem' }}>
                                  {item?.subject}
                                  {item?.send_schedule_pause && (
                                    <span className={getClassnameForStatus(item.send_schedule_pause)}> {tidyName(item.send_schedule_pause)} </span>
                                  )}
                                </div>
                                {console.log('send to::::::::',item)}
                                <div className='mb-2'>{`Sent on ${moment(item?.created_at).format('Do MMM, YYYY')}`}</div>
                                <div
                                  // className='line-clamp-2'
                                  dangerouslySetInnerHTML={{ __html: item?.body }}></div>
                              </td>
                            </tr>
                          )}
                        </>
                      )
                    })}
                  </tbody>
                </Table>
              )}
            </div>
            <div style={{ paddingBottom: '3rem' }}>
              <Pagination className='mt-3 mr-2 ml-2 email-pagination'>
                {currentPageData?.links?.map((item, key) => {
                  return (
                    <PaginationItem active={item.active} disabled={item.url === null} key={key}>
                      <PaginationLink
                        first
                        onClick={() => {
                          fetchMoreData(item?.url)
                        }}
                        className={item.active ? renderBlockColorClass(primaryColor) : linkClassName}
                        // style={hoveredRow === item?.id && { ...renderBlockColorStyle(primaryColor) }}
                        style={item.active ? { ...renderBlockStyle(primaryColor) } : { ...renderBlockColorStyle(primaryColor) }}>
                        {item.label.replace('&laquo;', '').replace('&raquo;', '')}
                      </PaginationLink>
                    </PaginationItem>
                  )
                })}
              </Pagination>
            </div>
          </>
        )}
      </div>
    </Card>
  )
}

export default MailContent
